(function () {
	'use strict';

	angular
		.module('app')
		.factory('salesforceAPI', [
			'salesforceAPIConfig',
			'salesforceShared',
			'seedcodeCalendar',
			'calendarIO',
			'daybackIO',
			'manageSettings',
			'utilities',
			'onboardingTemplate',
			salesforceAPI,
		]);

	function salesforceAPI(
		salesforceAPIConfig,
		salesforceShared,
		seedcodeCalendar,
		calendarIO,
		daybackIO,
		manageSettings,
		utilities,
		onboardingTemplate
	) {
		var sharedMethods = salesforceShared.getShared(
			'sfApi',
			sfApi,
			createConnection
		);
		//Create our connection for api services
		var connection = createConnection();

		var eventEditQueue = {};

		var primarySource;
		var primarySourceTemplate;

		sfApi.setErrorReporter(errorReporter);

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getObjectInfo: getObjectInfo,
			getResourceObjects: getResourceObjects,
			getSecondaryAuth: getSecondaryAuth,
			deleteSecondaryAuth: deleteSecondaryAuth,
			onboarding: onboarding,
			auth: auth,
			deauthorize: deauthorize,
			getEventEditQueue: getEventEditQueue,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			getUnscheduled: getUnscheduled,
			getMapEvents: getMapEvents,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			getContacts: getContacts,
			getProjects: getProjects,
			getResources: getResources,
			getFieldData: getFieldData,
			getPickList: getPickList,
			getRepeatingRule: getRepeatingRule,
			deleteSourceEvent: deleteSourceEvent,
			repeatingConfig: repeatingConfig,
			calculateEndDateTime: calculateEndDateTime,
			clearRecurringFields: clearRecurringFields,
			deleteRepeatingInstance: deleteRepeatingInstance,
			updateRepeatingEventUntil: updateRepeatingEventUntil,
			onSignOut: onSignOut,
		};

		function getConfig() {
			return salesforceAPIConfig.config();
		}

		function getFieldMap() {
			return salesforceAPIConfig.fieldMap();
		}

		function getUnusedMap() {
			return salesforceAPIConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return salesforceAPIConfig.allowHTMLMap();
		}

		function getHiddenFieldMap() {
			return salesforceAPIConfig.hiddenFieldMap();
		}

		function getReadOnlyFieldMap() {
			return salesforceAPIConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return salesforceAPIConfig.allowTextFieldMap();
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function getObjectInfo(objectName, callback) {
			sfApi.objectInfo(objectName, callback);
		}

		function getResourceObjects(resourceID, source, callback) {
			sfApi.resourceObjects(resourceID, source, callback);
		}

		function createConnection(schedule) {
			var user = daybackIO.getUser();

			var connection;

			if (schedule) {
				connection = {
					objectName: schedule.objectName,
					fieldMap: schedule.fieldMap,
				};
			} else {
				connection = salesforceAPIConfig.apiConnection();
			}
			return connection;
		}

		function errorReporter(
			status,
			additionalMessage,
			preventDeauth,
			callback
		) {
			var config = seedcodeCalendar.get('config');

			var message = 'There was a problem communicating with Salesforce.';

			if (config.passthroughEditErrors) {
				return;
			}

			if (additionalMessage) {
				message += ' ' + additionalMessage;
			}

			if (!preventDeauth && (status === 401 || status === 403)) {
				message += ' - ' + 'please re-authorize DayBack.';
				if (primarySourceTemplate)
					deauthorize(callback, false, primarySourceTemplate);
			}
			if (!config.suppressEditEventMessages) {
				utilities.showMessage(message, 0, 8000, 'error');
			}
		}

		function getSecondaryAuth(userID, groupID, sourceID, callback) {
			sfApi.getSecondaryAuth(userID, groupID, sourceID, callback);
		}

		function deleteSecondaryAuth(userID, groupID, sourceID, callback) {
			sfApi.deleteSecondaryAuth(userID, groupID, sourceID, callback);
		}

		function onboarding(onboardingAdd) {
			// onboardingAdd contains methods for creating onboarding data
			// onboardingAdd.defaultSources
			// Triggered in addSource funciton in settings-controller
			var defaultSources = {};
			onboardingTemplate.generateSalesforceSources(
				defaultSources,
				getConfig().id
			);

			onboardingAdd.defaultSources(defaultSources);
		}

		function auth(
			callback,
			source,
			statusOnly,
			forceConsent,
			secondaryAuth,
			sourceTemplate
		) {
			var groupAuthSources = seedcodeCalendar.get('groupAuthSources');
			var config = seedcodeCalendar.get('config');
			var user = daybackIO.getUser();
			if (!source) {
				source = primarySource;
			}

			// Use a group level auth token instead of user specific
			var useGroupToken =
				groupAuthSources[source.sourceTypeID] &&
				config.userGroupAuthSources[source.sourceTypeID];

			// Secondary auth is used when configuring group level token
			// Setting which auth token should be used for the group level auth token
			if (secondaryAuth) {
				forceConsent = true;
				useGroupToken = true;
			}

			sfApi.auth(
				user.id,
				source.id,
				statusOnly,
				forceConsent,
				connection.authIsRedirect,
				connection.authRedirectFunction,
				useGroupToken && user && user.group ? user.group.id : null,
				secondaryAuth,
				preAuthDialog,
				authResponseCallback
			);

			function preAuthDialog(callback) {
				utilities.showModal(
					'Salesforce Authentication',
					'Do you want to log in to a sandbox or production environment?',
					'Cancel',
					function () {
						callback(null);
					},
					'Sandbox',
					function () {
						callback('test');
					},
					'Production',
					function () {
						callback('login');
					}
				);
			}

			function authResponseCallback(result) {
				var title;
				var message;
				if (result && result.errorCode === 400) {
					// deauthorize(null, null, primarySourceTemplate);
					title = 'Error Communicating with Salesforce';
					message =
						'<p>There was a problem communicating with Salesforce. This is likely due to CORS not being set up for DayBack in your Salesforce org.</p><p>Please follow the <a href="https://docs.dayback.com/article/292-granting-access-to-dayback-cors" target="_blank">instructions here</a>.</p><p>Once that is done click "Try Again".</p>';
					utilities.showModal(
						title,
						message,
						'Cancel',
						function () {
							deauthorize(callback, null, sourceTemplate);
						},
						'Try Again',
						function () {
							auth(
								callback,
								source,
								statusOnly,
								forceConsent,
								secondaryAuth,
								sourceTemplate
							);
						}
					);
				} else {
					callback(result && !result.errorCode ? result : false);
				}
			}

			// Run auth function if no source is supplied it will auth into first one loaded (this should work unless you allow multiple simultaneous accounts)
			// Make sure to run callback when complete if the result of the callback is a false value it is assumed auth was cancelled. A truthy value assumes success
		}

		function deauthorize(callback, saveToken, sourceTemplate) {
			var user = daybackIO.getUser();
			var source = primarySource
				? primarySource
				: setPrimarySource(sourceTemplate);
			var schedules = seedcodeCalendar.get('schedules');
			var calendarElement = seedcodeCalendar.get('element');
			var eventSources = seedcodeCalendar.get('eventSources');
			var groupAuthSources = seedcodeCalendar.get('groupAuthSources');
			var config = seedcodeCalendar.get('config');

			var retainedSchedules = [];
			var removedSchedules = [];

			// Use a group level auth token instead of user specific
			var usingGroupToken =
				!saveToken &&
				groupAuthSources[source.sourceTypeID] &&
				config.userGroupAuthSources[source.sourceTypeID];
			if (usingGroupToken) {
				var modalTitle = 'Cannot Deauthorize Shared Authentication';
				var modalMessage =
					'You are currently authenticating with shared credentials and cannot deauthorize. You will need to turn off "Do not require Salesforce authentication" in group member settings first.';
				utilities.showModal(
					modalTitle,
					modalMessage,
					'OK',
					null,
					null,
					null
				);
				return;
			}

			// dataStore.clearState(saveStateString);
			sfApi.deauthorize(user.id, source.id, saveToken, function () {
				// Remove auth status
				sourceTemplate.status.authed = false;

				// Only try to update calendar if it exists - Not used when initiate from settings
				if (seedcodeCalendar.get('view')) {
					// Remove Schedules and events
					for (var i = 0; i < schedules.length; i++) {
						if (schedules[i].sourceTypeID !== sourceTemplate.id) {
							retainedSchedules.push(schedules[i]);
						} else {
							removedSchedules.push(schedules[i]);
						}
					}

					seedcodeCalendar.init('schedules', retainedSchedules);

					for (var i = 0; i < removedSchedules.length; i++) {
						// Remove event sources from fullcalendar
						calendarElement.fullCalendar(
							'removeEventSource',
							removedSchedules[i].source,
							removedSchedules[i].id
						);

						//Remove event source from eventSourcesObject
						if (
							eventSources.indexOf(removedSchedules[i].source) >
							-1
						) {
							eventSources.splice(
								eventSources.indexOf(
									removedSchedules[i].source
								),
								1
							);
						}
					}

					// Remove orphanes
					var events = calendarElement.fullCalendar('clientEvents');
					for (var i = 0; i < events.length; i++) {
						if (
							events[i].schedule.sourceTypeID ===
							sourceTemplate.id
						) {
							calendarElement.fullCalendar(
								'removeEvents',
								events[i]
							);
						}
					}
				}

				if (callback) {
					callback();
				}
			});
		}

		function updateRepeatingEventUntil(event, newUntil, callback) {
			sharedMethods.updateRepeatingEventUntil(event, newUntil, callback);
		}

		function deleteRepeatingInstance(event, callback) {
			deleteEvent(event, callback, event.schedule);
		}

		function repeatingConfig() {
			return sharedMethods.repeatingConfig();
		}

		function clearRecurringFields(editObject) {
			sharedMethods.clearRecurringFields(editObject);
		}

		function calculateEndDateTime(request, ruleObject) {
			return sharedMethods.calculateEndDateTime(request, ruleObject);
		}

		function getRepeatingRule(request, ruleObject) {
			return sharedMethods.getRepeatingRule(request, ruleObject);
		}

		function deleteSourceEvent(event, callback) {
			sharedMethods.deleteSourceEvent(event, callback, onError);

			function onError(result) {
				var message;
				if (result[0] && result[0].errorCode) {
					message =
						'There was an error deleting the item: ' +
						cleanError(result[0].message);
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
				}

				function confirmError() {
					return;
				}
			}
		}

		function getPickList(object, field, schedule, callback) {
			sharedMethods.getPickList(object, field, schedule, callback);
		}

		function getFieldData(objectName, callback) {
			sharedMethods.getFieldData(objectName, callback);
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var userProfile = sfApi.getUserProfile();
			var schedules = [];
			var schedule;
			var source;

			primarySourceTemplate = sourceTemplate;
			//Locate our dayback source data for salesforce
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					source = sources[i];

					//Run our authorization
					if (sources[i].localParent) {
						if (userProfile) {
							source.name = userProfile.name;
							source.email = userProfile.accountName;
						}

						primarySource = source;
						auth(
							authCallback,
							source,
							true,
							null,
							null,
							sourceTemplate
						);
						break;
					}
				}
			}

			return [];

			function authCallback(auth) {
				if (auth) {
					getCalendarList();
				} else {
					callback(false, sourceTemplate);
				}
				sourceTemplate.status.authed = auth;
			}

			function getCalendarList() {
				// Run routine to get list of calendars then run calendarListCallBack on success
				for (var i = 0; i < sources.length; i++) {
					if (
						sources[i].sourceTypeID === sourceTemplate.id &&
						sources[i].id !== primarySource.id
					) {
						schedule = setSchedule(sources[i]);
						schedules.push(schedule);
					}
				}

				sfApi.saveSchedules(schedules);

				callback(schedules, sourceTemplate);
			}

			function setSchedule(source) {
				var schedule = {};

				schedule.customFields = primarySource.customFields
					? JSON.parse(JSON.stringify(primarySource.customFields))
					: null;
				schedule.customActions = primarySource.customActions
					? JSON.parse(JSON.stringify(primarySource.customActions))
					: null;
				schedule.eventActions = primarySource.eventActions
					? JSON.parse(JSON.stringify(primarySource.eventActions))
					: null;

				//Append schedule specific items to already specified source items
				for (var property in source) {
					if (
						property === 'eventActions' ||
						property === 'customActions' ||
						property === 'customFields'
					) {
						if (!schedule[property]) {
							schedule[property] = source[property]
								? JSON.parse(JSON.stringify(source[property]))
								: null;
						} else {
							for (var calendarProperty in source[property]) {
								schedule[property][calendarProperty] =
									source[property][calendarProperty];
							}
						}
					} else {
						schedule[property] = source[property]
							? JSON.parse(JSON.stringify(source[property]))
							: null;
					}
				}

				//Clean schedule data
				schedule.sourceID = primarySource.id;
				//Normalize read only
				schedule.editable = sourceTemplate.editable;

				// Set source specific custom field properties
				if (schedule.customFields) {
					for (var property in schedule.customFields) {
						schedule.customFields[property].mutateInput =
							sharedMethods.customFieldMutateInput;
					}
				}

				//Clean schedule data
				calendarIO.cleanSchedule(schedule, sourceTemplate);

				return schedule;
			}
		}

		function setPrimarySource(sourceTemplate) {
			var sources = seedcodeCalendar.get('sources');
			var userProfile = sfApi.getUserProfile();
			var source;
			//Locate our dayback source data for salesforce
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					source = sources[i];

					//Run our authorization
					if (sources[i].localParent) {
						if (userProfile) {
							source.name = userProfile.name;
							source.email = userProfile.accountName;
						}

						primarySource = source;
						break;
					}
				}
			}
			return primarySource;
		}

		function changeScheduleName(name, callback, schedule) {
			manageSettings.setCalendarName(name, schedule, callback);
			return name;
		}

		function changeScheduleColor(color, callback, schedule) {
			manageSettings.setBackgroundColor(color, schedule, callback);
			return color;
		}

		function disableSchedule(scheduleID) {
			sharedMethods.disableSchedule(scheduleID);
		}

		function getUnscheduled(callback, schedule) {
			sharedMethods.getUnscheduled(callback, schedule);
		}

		function getMapEvents(bounds, callback, schedule) {
			sharedMethods.getMapEvents(bounds, callback, schedule, onError);

			function onError(result) {
				var message =
					'Salesforce Error: ' +
					cleanError(result[0].errorCode) +
					' Object Name: ' +
					schedule.name;
				utilities.showMessage(message, 0, 8000, 'error', null, true);
			}
		}

		function getEvents(
			start,
			end,
			timezone,
			callback,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			sharedMethods.getEvents(
				start,
				end,
				timezone,
				callback,
				schedule,
				null,
				onError,
				requestOverride
			);

			function onError(result) {
				var message =
					'Salesforce Error: ' +
					cleanError(result[0].errorCode) +
					' Object Name: ' +
					schedule.name;
				utilities.showMessage(message, 0, 8000, 'error', null, true);
			}
		}

		//Edit Events
		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule
		) {
			sharedMethods.editEvent(
				event,
				revertObject,
				revertFunc,
				changes,
				editID,
				callback,
				schedule
			);
		}

		//Delete Event

		function deleteEvent(event, callback, schedule) {
			sharedMethods.deleteEvent(event, callback, schedule);
		}

		//utility func for making errors titlecase

		function cleanError(str) {
			return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
				return (
					txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
				);
			});
		}

		// get contacts by object and criteria for contact selector

		function getContacts(
			callback,
			object,
			searchField,
			displayField,
			criteria,
			schedule
		) {
			sharedMethods.getContacts(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			);
		}

		function getProjects(
			callback,
			object,
			searchField,
			displayField,
			criteria,
			schedule
		) {
			sharedMethods.getProjects(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			);
		}

		function getResources(
			callback,
			object,
			searchField,
			displayField,
			criteria,
			schedule
		) {
			sharedMethods.getResources(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			);
		}

		function onSignOut() {
			sfApi.clearTokenMemory();
		}
	}
})();
